<template>
  <div>
    <van-calendar
      v-model:show="show"
      class="az-calendar"
      :style="{ height: '100%' }"
      :title="t('key.base.select.date')"
      :show-confirm="false"
      :round="false"
      :show-mark="false"
      :row-height="44"
      :show-subtitle="false"
      :lazy-render="false"
      :default-date="defaultSelectedDate"
      :min-date="minDate"
      :max-date="maxDate"
      :close-on-popstate="true"
      close-icon-position="top-left"
      color="#121212"
      :formatter="formatter"
      @confirm="onConfirm"
    >
      <template #title>
        <div class="align-middle" text="lg left" p="y-2 l-12">
          {{ t('key.base.select.date') }}
        </div>
      </template>
      <template #month-title="slotProps">
        <slot name="month-title" :date="slotProps">
          <div class="mt-8 pl-4 text-left text-2xl text-[#121212]">
            {{ dayjs(slotProps.date).format('MMM YYYY') }}
          </div>
        </slot>
      </template>
      <template #bottom-info="slotProps">
        <slot name="bottom-info" :date="slotProps.date"></slot>
      </template>
    </van-calendar>
  </div>
</template>

<style lang="scss">
.az-calendar {
  .van-popup__close-icon {
    top: 12px;
  }
  .van-calendar__header-title {
    height: 60px;
  }
  .van-calendar__selected-day {
    color: #fff;
    border-radius: 50%;
  }
  .van-calendar__day {
    margin-bottom: 10px;
  }
}
</style>

<script setup lang="ts">
import type { CalendarDayItem } from 'vant'

defineProps({
  maxDate: {
    type: Date,
    default: () => useDayjs()().add(3, 'month').toDate(),
  },
  minDate: {
    type: Date,
    default: () => useDayjs()().toDate(),
  },
  defaultSelectedDate: {
    type: Date,
    default: () => new Date(),
  },
  formatter: {
    type: Function as PropType<(item: CalendarDayItem) => CalendarDayItem>,
  },
})
const emit = defineEmits(['confirm'])
const dayjs = useDayjs()
const { t } = useI18n()

const show = defineModel('show', {
  type: Boolean,
  default: false,
})

function onConfirm(value: string) {
  show.value = false
  emit('confirm', value)
}
</script>
