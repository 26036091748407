<template>
  <div>
    <div @click="showDatePopup = true">
      <slot :format-date="formatDate">
        <!-- 默认内容 -->
      </slot>
    </div>
    <BaseAZCalendar
      v-model:show="showDatePopup"
      :default-selected-date="defaultSelectedDate"
      @confirm="onConfirmCalendar"
    />
  </div>
</template>

<script lang="ts" setup>
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { SearchDateClicksClickEvent } from '~/types/event-tracking'

const props = withDefaults(defineProps<Props>(), {
  formatter: 'DD MMM, YYYY',
  isDetail: false,
  attrName: '',
})

const dayjs = useDayjs()

interface Props {
  formatter?: string
  isDetail?: boolean
  pageType?: string
  attrName?: string
}

const { locale } = useLocale()
const { showDatePopup } = useUseHomePopup()
const gtm = useGtm()
const { query } = useRoute()
const filterStore = useFilterConditionsStore()

if (dayjs().startOf('day').isAfter(filterStore.filterDateRef.get(props.isDetail))) {
  filterStore.filterDateRef.set(props.isDetail, dayjs().startOf('day').valueOf())
}

const selectDate = computed(() => {
  return filterStore.filterDateRef.get(props.isDetail) && dayjs(filterStore.filterDateRef.get(props.isDetail)).format('YYYY-MM-DD')
})

const formatDate = computed(() => {
  return selectDate.value ? dayjs(selectDate.value).format(props.formatter) : ''
})

const defaultSelectedDate = computed(() => {
  return new Date(selectDate.value || '')
})

function onConfirmCalendar(value: string) {
  // selectDate.value = value
  filterStore.filterDateRef.set(props.isDetail, value as any)
  trackSearchDateClick()
}
function trackSearchDateClick() {
  const eventParams: SearchDateClicksClickEvent = {
    event: ENUM_EventType.SearchDateClick,
    page_category: props.isDetail ? ENUM_PageName.Detail : (props.pageType === 'home' ? ENUM_PageName.Home : ENUM_PageName.List),
    city: filterStore.filterConditions.selectedCity?.destinationName,
    city_id: filterStore.filterConditions.selectedCity?.destinationId,
    country_code: filterStore.filterConditions.selectedCity?.countryCode,
    search_date: dayjs(filterStore.filterConditions.date).format('YYYY-MM-DD'),
    locale: locale.value,
    campaign_id: query.campaign_id as string ?? '',
    campaign_name: query.campaign_name as string ?? '',
    attraction_id: props.isDetail ? query.attrId as string ?? '' : '',
    attraction_name: props.isDetail ? props?.attrName ?? '' : '',
  }
  gtm?.trackEvent(eventParams)
}
</script>
